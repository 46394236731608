import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { LinkPreview } from '../linkPreviews';
import { useSelectedNote } from '../notes/note';

export interface Source extends LinkPreview {
  url: string;
  count: number;
}

export type SourceCount = Pick<Source, 'url' | 'count'> &
  Partial<Pick<Source, 'title'>>;

export const groupedNoteSourcesState = atom<SourceCount[]>({
  key: 'groupedNoteSources',
  default: [],
});

export const useGroupedNoteSources = (): SourceCount[] => {
  const note = useSelectedNote();
  const state = useRecoilValue(groupedNoteSourcesState);

  return note
    ? state.map((s, index) => {
        const citation = note.metadata.citations.find(
          (c: { count: number; title?: string; url?: string }) =>
            c.count === index + 1,
        );
        return {
          ...s,
          title: citation?.title,
        };
      })
    : state;
};

export const useSetGroupedNoteSources = () =>
  useSetRecoilState(groupedNoteSourcesState);
