import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import {
  add,
  each,
  entries,
  flow,
  groupBy,
  isEmpty,
  isEqual,
  keys,
  map,
  mapValues,
  mergeWith,
  pick,
  reduce,
  tap,
  uniq,
  uniqBy,
} from 'lodash/fp';
import {
  atom,
  atomFamily,
  RecoilState,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { useRouter } from 'next/router';
import { log } from '../../utils/log';
import {
  ContentTypesKey,
  DEFAULT_CONTENT_TYPE,
  CONTENT_TYPES,
  Counts,
} from '../contentTypes/constants';
import { checkMinimumImageSize } from '../utils/image';
import { usePusherEventWithChunking } from '../utils/usePusherEventWithChunking';
import { useChannel } from './channel';
import {
  fullTextSearchQueryState,
  useFullTextSearchQuery,
} from './fullTextSearchQuery';
import { useIsLoading } from './page';
import {
  resultsState,
  searchQueryIdState,
  useSearchQueryId,
  useSearchResults,
} from './searchResult';
import {
  selectedContentTypeRawState,
  selectedContentTypeState,
  useSelectedContentType,
} from './selectedContentType';
import { selectedSourcesState } from './selectedSources';
import { useSetTopEntities } from './topEntities';
import {
  TopLink,
  useAddTopLink,
  useAllResults,
  useSetTopLinks,
} from './topLink';
import { Entity, SearchResult } from './types';
import { searchQueryState, useSearchQuery } from './waldoQuery';
import {
  datapointConflictingState,
  datapointGroupState,
  ideateQueryRawState,
  sortedIdeateProjects,
  useIdeateQueryReady,
  useSetIdeateQueryError,
  useSetIdeateQueryReady,
  useSetSelectedIdeateProject,
  useSetSummaryReady,
  useUpdateIdeateProject,
  useUpdateSelectedIdeateProject,
} from '@/modules/search/datapoints';
import { DatapointGroup } from '@/components/pages/DatapointsPage/types';
import { extensionInstalledState } from './engine';
import { userAsyncState } from './user';
import { shouldTrackUser } from '@/ext/lib/tracking';
import { triggerPrefetchFallback } from './prefetch';
import { searchSummaryRawState } from '@/modules/search/summary';
import { useBriefSummaryPusherEvent } from '@/modules/search/briefSummary';
import { IdeateProject } from '@/ext/types';
import { queryClient } from '@/components/providers/Dependencies';

export interface About {
  metadata: Metadata;
  readingTime: number;
  wordCount: number;
}

export interface DataPointEntity {
  type: string;
  value: string;
}

export interface Match {
  match: string;
  matchStart: string;
  matchEnd: string;
  matchContextAfter: string;
  matchContextFull: string;
  matchMarkdown: string;
  matchContextFullMarkdown: string;
  matchContextAfterMarkdown: string;
}

export type MatchTokenTypes =
  | 'CARDINAL'
  | 'DATE'
  | 'GPE'
  | 'MONEY'
  | 'ORG'
  | 'PERCENT'
  | 'QUANTITY';

export const DISTINCT_ENTITIES = [
  'DATE',
  'MONEY',
  'PERCENT',
  'QUANTITY',
  'CARDINAL',
];

export type MatchToken = [string, MatchTokenTypes];

export interface DataPoint extends Match {
  distance: number;
  matchTokens: MatchToken[];
}

export interface KeyText extends DataPoint {}

export interface Keyword extends Match {}

export interface Metadata {
  author: string;
  contentType?: string;
  date: string;
  description: string;
  image?: string;
  logo: string;
  publisher: string;
  title: string;
  url: string;
}

export interface SearchResultContent {
  about: About;
  dataPoints: DataPoint[];
  dates: DataPoint[];
  emails: DataPoint[];
  images: string[];
  keywords: DataPoint[];
  links: string[];
  linksWithMentions: TopLink[];
  phones: Match[];
  quotations: DataPoint[];
  socialLinks: string[];
  topEntities: Entity[];
  topLinks: TopLink[];
  cardinals: number[];
}

export interface SearchResultContentResponse {
  data: SearchResultContent;
  error: boolean;
  page: {
    fullTextSearchQuery?: string;
    searchQuery: string;
    url: string;
  };
}

type DatapointGroupingResponse = {
  [url: string]: number[];
};

export type GptRawDataResponse = {
  content: string;
  finished: boolean;
  error: boolean;
  index: number;
};

export enum StaticContentTypes {
  IMAGES = 'images',
  LINKS = 'linksWithMentions',
  SOCIAL_LINKS = 'socialLinks',
  ABOUT = 'about',
  TOP_ENTITIES = 'topEntities',
  TOP_LINKS = 'topLinks',
  CARDINALS = 'cardinals',
}

export interface SearchResultContentKey {
  contentType?: ContentTypesKey | StaticContentTypes;
  fullTextSearchQuery?: string | null;
  searchQuery: string;
  url?: string;
}

export interface SearchResultContentState {
  data?: SearchResultContent;
  error?: boolean;
  loading: boolean;
  dataUpdatedAt?: number;
}

export type GetSearchResultContentState = (
  props: SearchResultContentKey,
) => SearchResultContentState;

export const searchResultContent = atomFamily<SearchResultContentState, string>(
  {
    key: 'searchResultContent',
    default: { loading: true },
  },
);

// This is being used to trigger no results hook
export const loadedSearchResultContentTypeCountState = atomFamily<
  number,
  ContentTypesKey | StaticContentTypes
>({
  key: 'loadedSearchResultContentTypeCount',
  default: 0,
});

export const rawDatapointsState = atom<any[]>({
  key: 'rawDatapointsState',
  default: [],
});

export const getSearchResultContentQueryKey = ({
  contentType = DEFAULT_CONTENT_TYPE,
  fullTextSearchQuery = null,
  searchQuery,
  url,
}: SearchResultContentKey): string =>
  JSON.stringify([
    searchQuery,
    url,
    Object.values<string>(StaticContentTypes).includes(contentType)
      ? null
      : fullTextSearchQuery || null,
    contentType,
  ]);

export const searchResultContentSelector = selectorFamily<
  SearchResultContentState,
  { url: string; props?: Partial<SearchResultContentKey> }
>({
  key: 'searchResultContentSelector',
  get:
    ({ url, props }) =>
    ({ get }) => {
      const { searchQuery } = get(searchQueryState);
      const contentType = get(selectedContentTypeState);
      const fullTextSearchQuery = get(fullTextSearchQueryState);
      const key = getSearchResultContentQueryKey({
        contentType: props?.contentType || contentType,
        fullTextSearchQuery: props?.fullTextSearchQuery || fullTextSearchQuery,
        searchQuery: props?.searchQuery || searchQuery,
        url,
      });

      return get(searchResultContent(key));
    },
});

export const useSearchResultContentState = (
  url: string,
  props?: Partial<SearchResultContentKey>,
): SearchResultContentState =>
  useRecoilValue(searchResultContentSelector({ url, props }));

export const useSearchResultContentStaticState = <T extends StaticContentTypes>(
  url: string,
  state: T,
): {
  loading: boolean;
  error: boolean | undefined;
  data: SearchResultContent[T] | undefined;
} => {
  const searchQuery = useSearchQuery();
  const key = getSearchResultContentQueryKey({
    contentType: state,
    fullTextSearchQuery: null,
    searchQuery,
    url,
  });
  const { data, error, loading } = useRecoilValue(searchResultContent(key));
  return { loading, error, data: data?.[state] };
};

export const datapointsFilterState = atom<MatchTokenTypes | undefined>({
  key: 'datapointsFilterState',
  default: undefined,
});

export const useDatapointsFilter = () => useRecoilValue(datapointsFilterState);
export const useSetDatapointsFilter = () =>
  useSetRecoilState(datapointsFilterState);

export const dataTabState = atom<'dataPoints' | 'keyText' | 'quotes'>({
  key: 'dataTabState',
  default: 'dataPoints',
});

export const useDataTab = () => useRecoilValue(dataTabState);
export const useSetDataTab = () => useSetRecoilState(dataTabState);

export const useAbout = (
  url: string,
): {
  loading: boolean;
  error: boolean | undefined;
  about: About | undefined;
} => {
  const {
    loading,
    error,
    data: about,
  } = useSearchResultContentStaticState(url, StaticContentTypes.ABOUT);
  return { loading, error, about };
};

export const useImages = (
  url: string,
): {
  loading: boolean;
  images: string[] | undefined;
} => {
  const { about: { metadata: { image } = {} as Metadata } = {} as About } =
    useAbout(url);
  const { loading, data } = useSearchResultContentStaticState(
    url,
    StaticContentTypes.IMAGES,
  );
  const [images, setImages] = useState<string[]>([]);

  const rawImages = useMemo((): string[] => {
    // Setting an empty array as the default value for `data` resulted
    // in an infinite re-render.
    const staticImages = data || [];

    return image ? uniq([image, ...staticImages]) : staticImages;
  }, [data, image]);

  useEffect(() => {
    let isMounted = true;

    if (rawImages) {
      // 'Promise.all' for getting all images analyzed.
      // After all promises are fulfilled, sets the images state.
      Promise.all(
        rawImages.map((src) =>
          checkMinimumImageSize(src)
            .catch((e) => log('checkMinimumImageSize:', e))
            .then((ok) => (ok ? src : undefined)),
        ),
      )
        .then((v) => v.filter(Boolean))
        .then((v) => isMounted && setImages(v as string[]));
    }

    return () => {
      isMounted = false;
    };
  }, [rawImages]);

  return { loading, images };
};

export const useSocialLinks = (
  url: string,
): {
  loading: boolean;
  socialLinks: string[] | undefined;
} => {
  const { loading, data: socialLinks } = useSearchResultContentStaticState(
    url,
    StaticContentTypes.SOCIAL_LINKS,
  );
  return { loading, socialLinks };
};

export const useLinks = (
  url: string,
): {
  loading: boolean;
  links: TopLink[] | undefined;
} => {
  const { loading, data: links } = useSearchResultContentStaticState(
    url,
    StaticContentTypes.LINKS,
  );
  return { loading, links };
};

const EMPTY_COUNTS: Counts = {
  keywords: 0,
  dataPoints: 0,
  dates: 0,
  emails: 0,
  phones: 0,
  socialLinks: 0,
  quotations: 0,
  linksWithMentions: 0,
};

export type CountsKey = {
  searchQuery: string;
  fullTextSearchQuery?: string;
  url?: string; // sentinel value of undefined => implies counts for all URLs
};

const getCountsKey = (key: CountsKey): string =>
  JSON.stringify([key.searchQuery, key.fullTextSearchQuery, key.url]);

const countsState = atomFamily<Counts, string>({
  key: 'countsState',
  default: EMPTY_COUNTS,
});

export const useCounts = (key: CountsKey): Counts =>
  useRecoilValue(countsState(getCountsKey(key)));

export const useSetCounts = (key: CountsKey): SetterOrUpdater<Counts> =>
  useSetRecoilState(countsState(getCountsKey(key)));

export const getSearchResultContentTypeCountKey = (
  contentType: ContentTypesKey | StaticContentTypes,
): RecoilState<number> => loadedSearchResultContentTypeCountState(contentType);

export const useSearchResultContentTypeCount = (
  contentType: ContentTypesKey | StaticContentTypes,
): number => {
  const state = getSearchResultContentTypeCountKey(contentType);
  return useRecoilValue(state);
};

type CountsUpdates = { key: CountsKey; data: SearchResultContent }[];

const useSearchResultContentUpdater = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      (response?: SearchResultContentResponse) => {
        if (!response) {
          return;
        }

        const currentSearchQuery = snapshot
          .getLoadable(searchQueryState)
          .getValue().searchQuery;

        const {
          data,
          error,
          page: { fullTextSearchQuery, searchQuery, url },
        } = response;
        const contentKeys = Object.keys(
          data || CONTENT_TYPES,
        ) as ContentTypesKey[];

        if (error) {
          const extensionInstalled = snapshot
            .getLoadable(extensionInstalledState)
            .getValue();

          if (extensionInstalled) {
            const searchQueryId = snapshot
              .getLoadable(searchQueryIdState)
              .getValue();

            const user = snapshot.getLoadable(userAsyncState).getValue();
            const track = shouldTrackUser(user);

            triggerPrefetchFallback(
              searchQueryId,
              url,
              searchQuery,
              fullTextSearchQuery,
              track,
            );
          }
        }

        // Ignore content not related to the current `searchQuery`.
        if (searchQuery !== currentSearchQuery) {
          return;
        }

        contentKeys.forEach((contentType) => {
          const key = getSearchResultContentQueryKey({
            contentType,
            fullTextSearchQuery,
            searchQuery,
            url,
          });
          const state = searchResultContent(key);
          const value = snapshot.getLoadable(state).getValue();

          const searchResultContentTypeCountState =
            getSearchResultContentTypeCountKey(contentType);
          set(searchResultContentTypeCountState, (count) => count + 1);

          const newRawDatapoints = data?.dataPoints;
          if (newRawDatapoints) {
            set(rawDatapointsState, (rawDatapoints) => [
              ...rawDatapoints,
              {
                url,
                datapoints: newRawDatapoints,
              },
            ]);
          }

          set(state, {
            ...value,
            data,
            error,
            loading: false,
            dataUpdatedAt: Date.now(),
          });
        });
      },
    [],
  );

const useDatapointGroupingUpdater = () =>
  useRecoilCallback(
    ({ set }) =>
      (
        state: (param: string) => RecoilState<DatapointGroup | undefined>,
        response?: DatapointGroupingResponse,
      ) => {
        if (!response) {
          return;
        }

        Object.entries(response).forEach(([key, value]) => {
          value.forEach((val) => {
            set(state(`${key}:${val}`), (current) =>
              mergeWith(
                (a, b) => uniq([...(a ?? []), ...(b ?? [])]),

                current ?? {},
                response,
              ),
            );
          });
        });
      },
    [],
  );

const useUpdateCounts = (): ((updates: CountsUpdates) => void) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      flow(
        (updates) => updates.splice(0),
        groupBy(({ key }) => JSON.stringify(key)),
        mapValues(
          flow(
            map('data'),
            map(mapValues('length')),
            reduce(mergeWith(add), EMPTY_COUNTS),
            pick(keys(EMPTY_COUNTS)),
          ),
        ),
        entries,
        each(([serializedKey, data]) => {
          const key = JSON.parse(serializedKey);
          const state = countsState(getCountsKey(key));
          const currentCounts = snapshot.getLoadable(state).getValue();
          const newCounts = mergeWith(add, currentCounts, data);

          if (!isEqual(newCounts, currentCounts)) {
            set(state, newCounts);
          }
        }),
      ),
    [],
  );

const useSearchResultCountsUpdater = () => {
  const interimCountsState = useMemo<CountsUpdates>(() => [], []);
  const updateCounts = useUpdateCounts();
  const debouncedUpdateCounts = useMemo(
    () => debounce(updateCounts, 300, { maxWait: 500 }),
    [updateCounts],
  );

  return useCallback(
    (response?: SearchResultContentResponse) => {
      if (!response) {
        return;
      }

      const {
        data,
        error,
        page: { fullTextSearchQuery, searchQuery, url },
      } = response;

      if (!error) {
        interimCountsState.push(
          {
            key: { searchQuery, fullTextSearchQuery },
            data,
          },
          {
            key: { searchQuery, fullTextSearchQuery, url },
            data,
          },
        );
        debouncedUpdateCounts(interimCountsState);
      }
    },
    [debouncedUpdateCounts, interimCountsState],
  );
};

export const useSearchResultContentSubscription = (): void => {
  const setTopEntities = useSetTopEntities();
  const setTopLinks = useSetTopLinks();
  const searchResultContentUpdater = useSearchResultContentUpdater();
  const searchResultCountsUpdater = useSearchResultCountsUpdater();
  const addTopLink = useAddTopLink();
  const channel = useChannel();
  const searchResultUpdater = useMemo(
    () => flow(tap(searchResultContentUpdater), tap(searchResultCountsUpdater)),
    [searchResultContentUpdater, searchResultCountsUpdater],
  );
  const setDatapointGrouping = useDatapointGroupingUpdater();
  const sqid = useSearchQueryId();
  const setSearchSummaryRawState = useSetRecoilState(
    searchSummaryRawState(sqid),
  );
  const setIdeateQueryRawState = useSetRecoilState(ideateQueryRawState);
  const setIdeateQueryReady = useSetIdeateQueryReady();
  const setIdeateQueryError = useSetIdeateQueryError();
  const isIdeateQueryReady = useIdeateQueryReady();
  const { replace } = useRouter();
  const setIdeateProject = useSetSelectedIdeateProject();
  const updateSelectedIdeateProject = useUpdateSelectedIdeateProject();
  const { mutate: updateIdeateProject } = useUpdateIdeateProject();
  const searchQueryId = useSearchQueryId();
  const setSummaryReady = useSetSummaryReady(searchQueryId);

  usePusherEventWithChunking<SearchResultContentResponse>(
    channel,
    'pageInfo',
    searchResultUpdater,
  );

  usePusherEventWithChunking<SearchResult>(
    channel,
    'topLinkMetadata',
    addTopLink,
  );

  usePusherEventWithChunking<TopLink[]>(channel, 'topLinks', setTopLinks);
  usePusherEventWithChunking<Entity[]>(channel, 'topEntities', setTopEntities);

  usePusherEventWithChunking<DatapointGroupingResponse>(
    channel,
    'duplicatedDatapoint',
    (response) => setDatapointGrouping(datapointGroupState, response),
  );

  usePusherEventWithChunking<DatapointGroupingResponse>(
    channel,
    'conflictingDatapoints',
    (response) => setDatapointGrouping(datapointConflictingState, response),
  );

  usePusherEventWithChunking<GptRawDataResponse>(
    channel,
    'summary-2',
    ({ content, index, finished }) => {
      setSearchSummaryRawState((prev) => ({ ...prev, [index]: content }));
      if (finished) {
        setSummaryReady(true);
      }
    },
  );

  usePusherEventWithChunking<GptRawDataResponse>(
    channel,
    'topics',
    ({ content, finished, error, index }) => {
      setIdeateQueryRawState((prev) => {
        const newQuery = { ...prev, [index]: content };
        return newQuery;
      });

      if (error) {
        setIdeateQueryError(true);
      }

      if (isIdeateQueryReady !== finished) {
        setIdeateQueryReady(finished);
      }

      if (finished) {
        updateSelectedIdeateProject();
      }
    },
  );

  usePusherEventWithChunking<IdeateProject>(channel, 'project', (project) => {
    const projects =
      (queryClient.getQueryData('ideateProjects') as IdeateProject[]) || [];

    queryClient.setQueryData(
      'ideateProjects',
      sortedIdeateProjects(
        uniqBy('projectId', [
          { ...project, title: project.title.replaceAll('"', '') },
          ...projects,
        ]),
      ),
    );

    setIdeateProject(project);

    updateIdeateProject({
      ideateProject: {
        title: project.title.replaceAll('"', ''),
      },
      projectId: project.projectId,
    });

    replace({
      pathname: '/ideate',
      query: {
        projectId: project.projectId,
      },
    });
  });

  useBriefSummaryPusherEvent();
};

/**
 * A user is "filtering" when any of the following are true:
 * * the contentType is not the default
 * * a lens is selected
 * * a country, language, or date range is selected
 * * suggested keywords are selected
 * * a full text search has been requested
 */
export const isFilteringState = selector({
  key: 'isFiltering',
  get: ({ get }) => {
    const contentType =
      get(selectedContentTypeRawState) || DEFAULT_CONTENT_TYPE;
    const fullTextSearchQuery = get(fullTextSearchQueryState);

    return [!!fullTextSearchQuery, contentType !== DEFAULT_CONTENT_TYPE].some(
      (v) => v,
    );
  },
});

export const shouldShowSearchResultState = selectorFamily({
  key: 'shouldShowSearchResult',
  get:
    ({ domain, url }: Readonly<SearchResult>) =>
    ({ get }) => {
      const contentType =
        get(selectedContentTypeRawState) || DEFAULT_CONTENT_TYPE;
      const fullTextSearchQuery = get(fullTextSearchQueryState);
      const isFiltering = get(isFilteringState);
      const { searchQuery } = get(searchQueryState);
      const content = get(
        searchResultContent(
          getSearchResultContentQueryKey({
            contentType,
            fullTextSearchQuery,
            searchQuery,
            url,
          }),
        ),
      );
      const hasContent =
        content && (content.loading || !isEmpty(content.data?.[contentType]));
      const selectedSources = get(selectedSourcesState) || [];
      const conditions: boolean[] = [
        // When filtering, hide items we can't x-ray.
        !isFiltering || (isFiltering && hasContent),

        // Sources tool filtering.
        !selectedSources.length || selectedSources.includes(domain),
      ];

      return conditions.every((condition) => condition);
    },
});

export const useShouldShowSearchResult = (
  searchResult: SearchResult,
): boolean => useRecoilValue(shouldShowSearchResultState(searchResult));

export const useIsFiltering = (): boolean => useRecoilValue(isFilteringState);

const filteredSearchResultsState = selectorFamily({
  key: 'isFiltering',
  get:
    (searchResults: Readonly<SearchResult>[]) =>
    ({ get }) =>
      searchResults.filter((searchResult) =>
        get(shouldShowSearchResultState(searchResult)),
      ),
});

export const useFilteredSearchResults = (
  searchResults: SearchResult[],
): SearchResult[] => useRecoilValue(filteredSearchResultsState(searchResults));

export const loadingSomeContentSelector = selector({
  key: 'searchResultContentLoadingSome',
  get: ({ get }) => {
    const searchResults = get(resultsState);
    const contentType =
      get(selectedContentTypeRawState) || DEFAULT_CONTENT_TYPE;
    const fullTextSearchQuery = get(fullTextSearchQueryState);
    const { searchQuery } = get(searchQueryState);

    return searchResults.some(
      ({ url }) =>
        get(
          searchResultContent(
            getSearchResultContentQueryKey({
              contentType,
              fullTextSearchQuery,
              searchQuery,
              url,
            }),
          ),
        ).loading,
    );
  },
});

export const useLoadingSomeContent = (): boolean =>
  useRecoilValue(loadingSomeContentSelector);

export const useShouldShowNoResults = ():
  | 'no-content'
  | 'no-xray'
  | 'no-results'
  | undefined => {
  const allResults = useAllResults();
  const searchResults = useSearchResults();
  const filteredSearchResults = useFilteredSearchResults(allResults);
  const searchQuery = useSearchQuery();
  const contentType = useSelectedContentType();
  const fullTextSearchQuery = useFullTextSearchQuery();
  const counts = useCounts({
    searchQuery,
    fullTextSearchQuery,
  });
  const loading = useIsLoading();

  // Trigger updates from count changes.
  useSearchResultContentTypeCount(contentType);

  if (!searchQuery) {
    return undefined;
  }

  if (
    !loading &&
    !filteredSearchResults.length &&
    fullTextSearchQuery &&
    counts.keywords === 0
  ) {
    return 'no-xray';
  }

  if (!loading && !filteredSearchResults.length && contentType !== 'keywords') {
    return 'no-content';
  }

  if (
    !loading &&
    (!allResults.length ||
      (!filteredSearchResults.length && !searchResults.length))
  ) {
    return 'no-results';
  }

  return undefined;
};
