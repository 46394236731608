export const GOOGLE_ROOT_URL = 'https://www.google.com';

// The date format used in querystring parameters.
export const DATE_FORMAT = 'MM-DD-YYYY';

// The date format used when displaying search results
// based on the current language.
export const DISPLAYED_DATE_FORMATS = [
  'MMM. DD, YYYY',
  'DD MMM YYYY',
  'DD [of] MMM [of] YYYY',
  'YYYY',
  'H [hours ago]',
  'm [minutes ago]',
  's [seconds ago]',
  'M [months ago]',
  'D [days ago]',
  'H [hour ago]',
  'm [minute ago]',
  's [second ago]',
  'M [month ago]',
  'D [day ago]',
];

export const SINCE_DATE_FORMATS = [
  'H [hours ago]',
  'm [minutes ago]',
  's [seconds ago]',
  'M [months ago]',
  'D [days ago]',
  'H [hour ago]',
  'm [minute ago]',
  's [second ago]',
  'M [month ago]',
  'D [day ago]',
];

/**
 * Selectors used within Google Search results pages.
 */

export const SEARCH_CORRECTED_SEARCH_QUERY_SELECTOR = '#fprsl';

export const SEARCH_INPUT_SELECTOR = 'input[name="q"]';

export const SEARCH_RESULTS_NEXT_LINK_SELECTOR = '#pnnext';

export const SEARCH_RESULTS_SELECTOR = [
  // Normal search results.
  '#rso .g.tF2Cxc',
  '#rso .g:not(.g-blk) .tF2Cxc',

  // Video result
  '#rso .dFd2Tb',

  // Parent results when some results are nested.
  '#rso .g:not(.g-blk):not(.tF2Cxc) > div:nth-child(1) > div[data-ved]',
  '#rso .g:not(.g-blk):not(.tF2Cxc) .g',

  // Children results when some results are nested.
  '#rso .g:not(.g-blk):not(.tF2Cxc) ul li .XN9cAe > div[data-ved]',
  '.G8uHRd',
].join(', ');
export const SEARCH_RESULT_FEATURED_SELECTOR = '.g-blk';

export const EXCERPT_SELECTOR = [
  '.yXK7lf',
  '.IsZvec > div:only-child',
  '.IsZvec div + span',
  '.uUuwM[data-content-feature="3"] div',
  '.Z26q7c[data-content-feature="3"]',
  '.uUuwM[data-content-feature="2"] div',
  '.Z26q7c[data-content-feature="2"]',
  '.uUuwM[data-content-feature="1"] div',
  '.Z26q7c[data-content-feature="1"]',
  '.uUuwM div:last-child',
  '.IsZvec div',
  'span.cQp1Ab',
];

/**
 * Selectors used to scrape Google's Elements
 */

// Actors ("actors in wecrashed"). and places
export const ACTORS_AND_BUSINESS = '.M8OgIe > div > div > [id]:not([jsaction])';

export const ADS_ELEMENTS_SELECTOR = '#tads';

export const APPBAR_CONTENT_SELECTOR = '#appbar [data-q]';

export const CALCULATOR_SELECTOR = '[jscontroller="GCPuBe"]';

export const CAPTCHA_SELECTOR = '#captcha-form';

export const CONVERSIONS_SELECTOR = '.vk_c.card';

export const CURRENCY_CONVERSION_SELECTOR = '[data-attrid="Converter"]';

export const DIRECTIONS_SEARCH_SELECTOR =
  '[data-async-type="editableDirectionsSearch"]';

export const DICTIONARY_SELECTOR = '[data-query-term]';

export const TRANSLATE_SELECTOR = '[jscontroller="tZEiM"]';

// Some featured elements like for "monk fruit".
export const FEATURED_ELEMENT_SELECTOR = '.M8OgIe [role="complementary"]';

export const FEATURED_SNIPPET_SELECTOR = 'block-component > .g-blk';

// used only to style
export const FLIGHTS_SELECTOR = '.WlTAzf.mnr-c';

export const GOOGLE_PANEL_ELEMENT_CLASSNAME = 'W-GooglePanelElement';

// The right sidebar.
export const KNOWLEDGE_BOX_SELECTOR = '#rhs';

// Image buttons that can be displayed with elements like "Images for [search query]".
export const IMAGE_BUTTON_SOURCE_URL_ATTRIBUTE = 'data-lpage';
export const IMAGE_BUTTON_SELECTOR = `[${IMAGE_BUTTON_SOURCE_URL_ATTRIBUTE}][jsaction]`;

export const LYRICS_SELECTOR = '[data-lyricid]';

export const MEMBERS_SELECTOR = '#extabar [data-attrid]';

// The top menu displayed in Google (images, videos, maps, ...).
// TODO: confirm if these are becoming deprecated because of the new menu.
export const MENU_CONTAINER_SELECTOR = '#hdtb-msb';
export const MENU_ITEMS_SELECTOR = '#hdtb-msb div.hdtb-mitem a';
export const MENU_MORE_ITEMS_SELECTOR =
  '#hdtb-msb span.hdtb-mitem g-menu g-menu-item a';
export const MENU_MORE_CONTAINER_SELECTOR = '#hdtb-msb span.hdtb-mitem';

// The new menu Google shows at the top.
export const MENU_UPDATED_CONTAINER_SELECTOR = '#uddia_1';
export const MENU_UPDATED_ITEMS_SELECTOR = '#uddia_1 g-menu g-menu-item a';
export const MENU_UPDATED_ITEMS_NAME_SELECTOR =
  '#uddia_1 g-menu g-menu-item a span:nth-child(2)';

// The container element that has all of the content.
export const PEOPLE_ALSO_ASK_SELECTOR = '[data-initq] [jsname][data-bs][id]';
// Containers for all items.
export const PEOPLE_ALSO_ASK_ITEM_SELECTOR = `${PEOPLE_ALSO_ASK_SELECTOR} > div:not([id])`;
// The question itself.
export const PEOPLE_ALSO_ASK_QUESTION_SELECTOR = '[role=button]';
// The content for a question once it becomes available.
export const PEOPLE_ALSO_ASK_CONTENT_SELECTOR = '[jsslot]';

// selector for song, news, ...
export const OVERVIEW_SELECTOR =
  '[id="kp-wp-tab-overview"] > .TzHB6b.cLjAic.LMRCfc > [jsname="xQjRM"] > .sATSHe';

export const STATISTICS_SELECTOR =
  '[id="kp-wp-tab-HealthStats"] > .TzHB6b.cLjAic.LMRCfc > [jsname="xQjRM"] > .sATSHe';

export const PLACES_SELECTOR = '.WVGKWb[jscontroller="OWrb3e"]';

export const RELATED_SEARCHES_SELECTOR = '#w3bYAd';

export const SPORTS_SELECTOR = '#sports-app';
export const STOCK_SELECTOR = '.aviV4d';

export const TABBED_CAROUSEL_SELECTOR = 'g-tabbed-carousel';

export const TOP_STORIES_SELECTOR = '[aria-label="Top stories"]';
export const TOP_STORIES_HEADING_SELECTOR = `${OVERVIEW_SELECTOR} [role="heading"]`;

export const TWITTER_SELECTOR = '[data-show-author]';

export const VIDEOS_SELECTOR = '.uVMCKf.aNytqb';

export const WEATHER_SELECTOR = '#wob_wc';

export const WEATHER_AVERAGE_SELECTOR = '[data-attrid="Climate"]';

export const SEARCH_INSTEAD_SELECTOR = '#oFNiHe p';
export const SEARCH_INSTEAD_CONTENTS_SELECTOR = '.gL9Hy';

export const BOOKS_SELECTOR = '[jscontroller="qTdDb"]';

export const STOPWATCH_SELECTOR = 'block-component';

export const GOOGLE_ELEMENTS_SELECTOR = [
  '#rso > div', // almost all google elements
  FEATURED_ELEMENT_SELECTOR,
  SEARCH_INSTEAD_SELECTOR,
  ACTORS_AND_BUSINESS,
  APPBAR_CONTENT_SELECTOR,
  LYRICS_SELECTOR,
  MEMBERS_SELECTOR,
  OVERVIEW_SELECTOR,
  STATISTICS_SELECTOR,
  STOCK_SELECTOR,
  TWITTER_SELECTOR,
].join(',');
