import { useUser } from '@/ext/app/state/user';
import { Subscription, User } from '@/ext/types';

export const getUserSubscription = (user: User | undefined) =>
  user?.subscriptions?.find((s) => s.status === 'active') ||
  user?.subscriptions?.find((s) => s.status === 'trialing');

export const useUserSubscription = (): Subscription | undefined => {
  const user = useUser();
  return getUserSubscription(user);
};
