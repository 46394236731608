import { useState, KeyboardEvent, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  usePinnedFilterOptions,
  usePinFilterOption,
} from '@/ext/app/state/search/pinnedFilterOptions';
import { ExploreFormFilterListItemProps } from './types';
import { FilterOptionId } from '@/ext/app/state/search/types/filterOption';
import { exploreQueryRaw } from '@/ext/app/state/search/exploreQuery';
import { useSelectFilterOption } from '@/ext/app/state/search/selectedFilterOptions';
import { filterListHandler } from './utils';
import { isFilterDisabled } from '@/ext/app/state/search/isFilterDisabled';
import { useSetDialog } from '@/ext/app/state/dialog';
import { useUserSubscription } from '@/lib/utils/useUserSubscription';

interface Props extends ExploreFormFilterListItemProps {
  isActive: boolean;
  isPinned: boolean;
  disabled: boolean;
  onPin: (option: { option: FilterOptionId; value: boolean }) => void;
  onToggleActive: (active: boolean) => void;
  onApply: (option: FilterOptionId) => void;
  onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void;
}

export const useExploreFormFilterListItemProps = (
  props: ExploreFormFilterListItemProps,
): Props => {
  const { value, groupName, label } = props;
  const { data: pinnedOptions } = usePinnedFilterOptions();
  const { mutateAsync: onPin } = usePinFilterOption();
  const [isActive, setIsActive] = useState(false);
  const setQuery = useSetRecoilState(exploreQueryRaw);
  const handleSelectOption = useSelectFilterOption();
  const subscription = useUserSubscription();
  const setDialog = useSetDialog();

  const disabled = useMemo(
    () => isFilterDisabled({ groupName, value, label }, subscription?.name),
    [groupName, value, label, subscription?.name],
  );

  const isPinned = !!pinnedOptions?.some(
    (item) => item.value === value && item.groupName === groupName,
  );

  const handleToggleActive = (active: boolean) => setIsActive(active);

  const handleApply = (option: FilterOptionId) => {
    if (disabled) {
      setDialog({ dialog: 'pricingTable' });
      return;
    }
    handleSelectOption(option);
    setQuery('');
  };

  const handleComplete = () => {
    setQuery(`${groupName}:${label}`);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    filterListHandler(
      e,
      () => handleApply({ groupName, value, label }),
      handleComplete,
    );
  };

  return {
    isPinned,
    isActive,
    disabled,
    onPin,
    onToggleActive: handleToggleActive,
    onApply: handleApply,
    onKeyDown: handleKeyDown,
    ...props,
  };
};
